import { computed } from "vue-demi";
import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { usePartVendor } from "./partVendor.pinia";
import { useFind } from "../utils/feathers-pinia/lib";
import { useGetHelper } from "../utils/feathers-pinia/use-get-helpers";
import { formatPrice } from "../utils/composables";
import { has } from "lodash-es";
import { isRegionUS } from "../utils/variables";

const servicePath = "part-bom";
const usePartBom = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    byId: (state) => (partBomId) => {
      return state.items.filter((item) => item.id === partBomId);
    },
  },
  actions: {
    getBomPartIds(bomArray) {
      // Find all occurrences of child_part_id in the bomArray
      const bomRecursionHelper = (inputArray) => {
        return inputArray.reduce((acc, cur) => {
          // First, add cur.child_part_id to the accumulator
          acc = acc.concat(cur.child_part_id).filter((i) => !!i);
          // If cur has children, recurse on them
          if (cur.children?.length)
            acc = acc.concat(bomRecursionHelper(cur.children));
          // Remove duplicates
          acc = [...new Set(acc)];
          return acc;
        }, []);
      };

      return bomRecursionHelper(bomArray);
    },
    getBomPriceRollup(partId) {
      // @TODO remove this after calculations are in place
      if (!isRegionUS) {
        return {
          allBoms: computed(() => []),
          bomPriceRollup: computed(() => ({
            price: formatPrice(0),
            tree: [],
          })),
          isPending: computed(() => false),
        };
      }

      const partVendorStore = usePartVendor();

      const { item: partBomResult, isPending: isPendingPartBom } = useGetHelper(
        partId,
        this
      );

      const allBoms = computed(() => partBomResult?.value?.data ?? []);

      const allBomParts = computed(() => this.getBomPartIds(allBoms.value));

      const partVendorQuery = computed(() => {
        return {
          query: {
            part_id: { $in: allBomParts.value },
            $limit: 1000,
          },
        };
      });

      const { items: partVendorResults, isPending: isPendingPartVendor } =
        useFind({
          model: partVendorStore.Model,
          params: partVendorQuery,
          queryWhen: computed(() =>
            has(partVendorQuery.value, "query.part_id.$in[0]")
          ),
        });

      // Get the best ranked part vendor for each part
      const partVendorItems = computed(() => {
        return (
          partVendorResults.value
            // Filter out part vendors with rank 0
            .filter((partVendor) => parseFloat(partVendor.rank) > 0)
            // If there are multiple vendors with the same part_id, only keep the one with the lowest rank
            .reduce((acc, cur) => {
              const existingPartVendor = acc.find(
                (partVendor) => partVendor.part_id === cur.part_id
              );
              // If there is already a part vendor with the same part_id, check the rank
              if (existingPartVendor) {
                // If the current part vendor has a lower rank, replace the existing part vendor
                if (
                  parseFloat(cur.rank) < parseFloat(existingPartVendor.rank)
                ) {
                  acc = acc.filter(
                    (partVendor) => partVendor.part_id !== cur.part_id
                  );
                  acc.push(cur);
                }
              } else {
                // If there is no existing part vendor with the same part_id, add the current part vendor
                acc.push(cur);
              }
              return acc;
            }, [])
        );
      });

      const buildBomItems = (bomItems = [], partVendors = []) => {
        return bomItems.map((bomItem) => {
          // Recurse on the children
          const children = buildBomItems(bomItem.children, partVendors);
          // Find the part vendor for this bom item
          const partVendor = partVendors.find(
            (item) => item.part_id === bomItem.child_part_id
          );
          // Get pricing info
          const price = parseFloat(partVendor?.price ?? 0);
          const quantity = parseFloat(bomItem.quantity ?? 0);
          // Calculate the total price of the children
          const childrenCombinedPrice = children.reduce(
            (acc, cur) => acc + cur.total_price,
            0
          );
          // Calculate the total price of the current item, plus children (do this first to avoid multiplying by 0)
          const currentItemCombinedPrice = price + childrenCombinedPrice;
          // Calculate the total price of the current item * quantity
          const currentItemTotal = currentItemCombinedPrice * quantity;
          // Return the bom tree item
          return {
            id: bomItem.id,
            child_part_id: bomItem.child_part_id ?? null,
            part_number: bomItem.part?.part_number ?? null,
            quantity,
            price,
            // Total price is the combination of all child totals + current item price * quantity
            total_price: currentItemTotal,
            children,
          };
        });
      };

      // Get the total price of the bom tree, and the bom tree itself
      const bomPriceRollup = computed(() => {
        if (!allBoms.value?.length) return [];
        if (!partVendorItems.value.length) return [];
        // Get the bom tree
        const bomTree = buildBomItems(allBoms.value, partVendorItems.value);
        // Return the total price and the bom tree
        return {
          price: formatPrice(
            bomTree.reduce((acc, cur) => acc + cur.total_price, 0)
          ),
          tree: bomTree,
        };
      });

      return {
        allBoms,
        bomPriceRollup,
        isPending: computed(
          () => isPendingPartVendor.value || isPendingPartBom.value
        ),
      };
    },
  },
});

api.service(servicePath).hooks({});

export { usePartBom };
