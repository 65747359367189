import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "part-vendor";
const usePartVendor = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    byId: (state) => (partVendorId) => {
      return state.items.filter((item) => item.id === partVendorId);
    },
  },
});

api.service(servicePath).hooks({});

export { usePartVendor };
